import { useEffect, useState } from 'react';
import { Howl } from 'howler';

export const useAudio = (audiosrc: string) => {
  const [audio, setAudio] = useState<Howl | null>(null);

  useEffect(() => {
    const howl = new Howl({
      src: audiosrc,
      onloaderror: (soundId, error) => console.error(soundId, error),
      onplayerror: (soundId, error) => {
        console.error(soundId, error);
        howl.once('unlock', () => howl.play());
      },
    });

    setAudio(howl);

    return () => {
      howl.unload();
    }
  }, []);

  return [audio] as const;
}

export const useDing = () => {
  return useAudio(`${process.env.PUBLIC_URL}/ding.mp3`);
}
